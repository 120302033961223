
/* Asegúrate de importar o enlazar este archivo de estilos en tu componente React */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to bottom, #3498db, #2980b9);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.input-group {
  margin-bottom: 20px;
}

input[type="file"] {
  padding: 10px;
  border: 2px solid #3498db;
  border-radius: 5px;
  background: #fff;
  color: #3498db;
  cursor: pointer;
}

input[type="file"]:hover {
  background: #3498db;
  color: #fff;
}

input[type="text"] {
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid #3498db;
  border-radius: 5px;
  background: #fff;
  color: #3498db;
}

input[type="text"]:hover {
  background: #3498db;
  color: #fff;
}

.button {
    margin-top: 30px;
    margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #3498db;
  color: #fff;
}

.button:hover {
  background: #2980b9;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav a {
  text-decoration: none;
  color: #fff;
}

.placeh {
    width: 280px;
}