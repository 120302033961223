.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    background: linear-gradient(to right, #87c7f2, #143f5d);
  }
  
  .auth-input {
    margin: 10px;
    padding: 8px;
    border: 1px solid #3498db;
    border-radius: 4px;
    width: 200px;
  }
  
  .auth-button {
    margin: 10px;
    padding: 10px;
    background: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .auth-button:hover {
    background: #2980b9;
  }
  